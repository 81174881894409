@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: var(--nj-semantic-font-family-default);
  letter-spacing: normal;
}

th,
td {
  vertical-align: middle !important;
}

/* Material */
$hyppo-palette: (
  50: var(--nj-core-color-blue-50),
  100: var(--nj-core-color-blue-100),
  200: var(--nj-core-color-blue-200),
  300: var(--nj-core-color-blue-300),
  400: var(--nj-core-color-blue-400),
  500: var(--nj-core-color-blue-500),
  600: var(--nj-core-color-blue-600),
  700: var(--nj-core-color-blue-700),
  800: var(--nj-core-color-blue-800),
  900: var(--nj-core-color-blue-900),
  contrast: (
    50: var(--nj-semantic-color-text-neutral-primary-default),
    100: var(--nj-semantic-color-text-neutral-primary-default),
    200: var(--nj-semantic-color-text-neutral-primary-default),
    300: var(--nj-semantic-color-text-neutral-primary-default),
    400: var(--nj-semantic-color-text-neutral-primary-default),
    500: var(--nj-semantic-color-text-neutral-inverse-default),
    600: var(--nj-semantic-color-text-neutral-inverse-default),
    700: var(--nj-semantic-color-text-neutral-inverse-default),
    800: var(--nj-semantic-color-text-neutral-inverse-default),
    900: var(--nj-semantic-color-text-neutral-inverse-default),
  ),
);

$hyppo-typography: mat.define-typography-config(
  $font-family: Lato,
  $body-1:
    mat.define-typography-level(
      $font-family: Lato,
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5,
      $letter-spacing: normal,
    ),
);

$hyppo-primary: mat.define-palette($hyppo-palette, 600);
$hyppo-accent: mat.define-palette($hyppo-palette, 600);
$hyppo-theme: mat.define-light-theme(
  (
    color: (
      primary: $hyppo-primary,
      accent: $hyppo-accent,
    ),
    typography: $hyppo-typography,
  )
);

@include mat.all-component-themes($hyppo-theme);

/* Fluid overrides */

.material-icons {
  font-family: 'Material Symbols Outlined';
}

.nj-card__title {
  margin-top: 0 !important;
}

.bg-nj-additional-engieRay-default {
  // fix for the tailwind fluid token as its targeting the background-color
  background-image: linear-gradient(
    90deg,
    var(--nj-core-color-engie-blue) 0%,
    var(--nj-core-color-engie-green) 100%
  );
}

nj-link .nj-link {
  display: inline;
}

nj-sidebar {
  z-index: calc(var(--nj-semantic-elevation-z-index-fixed) - 30) !important;
}

.nj-modal__content {
  box-shadow: var(--nj-semantic-elevation-shadow-24-dp);
}

.nj-modal__footer {
  display: none !important;
}

/* Material overrides */
.mat-mdc-menu-panel {
  max-width: unset !important;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane {
  pointer-events: none;
}
